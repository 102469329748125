<template>
  <div class="px-15px px-lg-25px">
       <div class="aiz-titlebar mt-2 mb-4">
        <div class="row align-items-center">
            <div class="col-md-6">
                <h1 class="h3 text-primary">{{ $t('shou-ye') }}</h1>
            </div>
        </div>
    </div>

     <div class="row">
      <div class="col-sm-6 col-md-6 col-xxl-3">
        <div class="card shadow-none mb-4 bg-primary py-4">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-16 text-info">{{ $t('chan-pin') }}</span>
                </p>
                <h3 class="mb-0 text-white fs-30">
                  {{indexData.ProductCount}}
                </h3>
              </div>
              <div class="col-auto text-right">
                <img :src="cardUrl1" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-xxl-3">
        <div class="card shadow-none mb-4 bg-primary py-4">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-16 text-info">{{ $t('ping-fen') }}</span>
                </p>
                <h3 class="mb-0 text-white fs-30">
                  0
                </h3>
              </div>
              <div class="col-auto text-right">
                  <img :src="cardUrl2" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-xxl-3">
        <div class="card shadow-none mb-4 bg-primary py-4">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-16 text-info">{{ $t('zong-ding-dan') }}</span>
                </p>
                <h3 class="mb-0 text-white fs-30">
                  {{indexData.OrderCount}}
                </h3>
              </div>
              <div class="col-auto text-right">
                <img :src="cardUrl3" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-xxl-3">
        <div class="card shadow-none mb-4 bg-primary py-4">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-16 text-info">{{ $t('zong-xiao-shou-e') }}</span>
                </p>
                <h3 class="mb-0 text-white fs-30">
                  ${{ indexData.TotalSales }} </h3>
              </div>
              <div class="col-auto text-right">
                <img :src="cardUrl4" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
        <div class="card shadow-none bg-soft-primary">
          <div class="card-body">
            <div class="card-title text-primary fs-16 fw-600">
              {{ $t('xiao-shou-tong-ji') }} </div>
            <canvas id="graph-1" class="w-100" height="150"></canvas>
          </div>
        </div>
        <div class="card shadow-none bg-soft-primary mb-0">
          <div class="card-body">
            <div class="card-title text-primary fs-16 fw-600">
              {{ $t('shou-chu-jin-e') }} </div>
            <p>{{ $t('nin-de-xiao-shou-jine-dang-yue') }}</p>
            <h3 class="text-primary fw-600 fs-30">
              ${{indexData.ThisMonthSales}} </h3>
            <p class="mt-4"> {{ $t('shang-ge-yue-indexdatalastmonthsales')}} {{indexData.LastMonthSales}}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 mb-4" style="display: flex;flex-direction: column;justify-content: space-between;">
        <div class="card shadow-none mb-0">
          <div class="card-body">
            <div class="card-title text-primary fs-16 fw-600">
              {{ $t('lei-bie-ming-zhi-de-chan-pin-ji-shu') }} </div>
            <hr>
            <ul class="list-group">
              <!-- <li>11</li>
              <li>22</li> -->
            </ul>
          </div>
        </div>
        <div class="card shadow-none py-4 mt-1 mb-0" style="justify-self: end">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-16 text-info">{{ $t('fang-ke-liang') }}</span>
                </p>
                <h3 class="mb-0 text-primary fs-30 mt-1">
                  {{indexData.Visit}}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
        <div class="card h-450px mb-0 h-100">
          <div class="card-body">
            <div class="card-title text-primary fs-16 fw-600">
              {{ $t('ding-dan') }} <p class="small text-muted mb-0">
                <span class="fs-12 fw-600">{{ $t('zhe-ge-yue') }}</span>
              </p>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-auto text-left">
                <img :src="orderUrl1" alt="">
              </div>
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-13 text-primary fw-600">{{ $t('xin-ding-dan') }}</span>
                </p>
                <h3 class="mb-0 text-info">
                  {{indexData.OrderCount}}
                </h3>
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-auto text-left">
                <img :src="orderUrl2" alt="">
              </div>
              <div class="col">
                <p class="small text-muted mb-0">
                    <span class="fe fe-arrow-down fe-12"></span>
                    <span class="fs-13 text-primary fw-600">{{ $t('yi-qu-xiao') }}</span>
                </p>
                <h3 class="mb-0 text-info">
                  {{indexData.CancelOrder}}
                </h3>
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-auto text-left">
                <img :src="orderUrl3" alt="">
              </div>
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-13 text-primary fw-600">{{ $t('tou-di-zhong') }}</span>
                </p>
                <h3 class="mb-0 text-info">
                  {{indexData.Delivery}}
                </h3>
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-auto text-left">
                <img :src="orderUrl4" alt="">
              </div>
              <div class="col">
                <p class="small text-muted mb-0">
                  <span class="fe fe-arrow-down fe-12"></span>
                  <span class="fs-13 text-primary fw-600">{{ $t('que-ren-shou-huo') }}</span>
                </p>
                <h3 class="mb-0 text-info">
                  {{indexData.ConfirmReceipt}}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h6 class="mb-0">{{ $t('gou-mai-de-tao-can') }}</h6>
            </div>
            <div class="d-flex" v-if="packageInfo.ID">
              <div>
                <img :src="packageUrl" class="img-fluid mb-4 w-64px">
              </div>
              <div class="ml-3">
                <a class="fw-600 mb-3 text-primary">{{ $t('dang-qian-tao-can') }}</a>
                <h6 class="text-primary">{{packageInfo.LevelName}}</h6>
                <p class="mb-1 text-muted">
                  {{ $t('chan-pin-shang-chuan-xian-zhi-packageinfogoodslimit-ci', [packageInfo.GoodsLimit]) }} </p>
                <p class="text-muted mb-4">
                  {{ $t('tao-can-dao-qi-shi-jian-getendtimepackageinfocreatedat', [getEndTime(packageInfo.CreatedAt)]) }} </p>
                <div class="">
                  <a  class="btn btn-soft-primary">{{ $t('sheng-ji-tao-can') }}</a>
                </div>
              </div>
            </div>
            <h6 class="fw-600 mb-3 text-primary" v-else>{{ $t('mei-you-tao-can') }}</h6>
          </div>
        </div>
        <div class="card mb-0  px-4 py-5 d-flex align-items-center justify-content-center">
          <div class="my-n4 py-1 text-center" v-if="parseFloat(depositMoney) == 0">
            <img :src="verifyUrl2" class="w-xxl-130px w-90px d-block">
            <a  class="btn btn-sm btn-primary">{{ $t('ma-shang-yan-zheng') }}</a>
          </div>
          <div class="my-2 py-1" v-else>
            <img :src="verifyUrl" alt="" width="">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-3">
        <a   class="card mb-4 p-4 text-center bg-soft-primary h-180px" @click="toPath('financialCenter')">
          <div class="fs-16 fw-600 text-primary">
            {{ $t('cai-wu-zhong-xin') }} </div>
          <div class="m-3">
            <img :src="withdrawUrl" alt="">
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <a class="card mb-4 p-4 text-center h-180px"  @click="toPath('productStorehouse')">
          <div class="fs-16 fw-600 text-primary">
            {{ $t('tian-jia-xin-chan-pin') }} </div>
          <div class="m-3">
            <img :src="productUrl" alt="">
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <div class="card mb-4 p-4 text-center bg-soft-primary" @click="toPath('shopSetting')">
          <div class="fs-16 fw-600 text-primary">
            {{ $t('dian-pu-she-zhi') }} </div>
          <div class=" m-3">
            <img :src="shopUrl" alt="">
          </div>
          <a  class="btn btn-primary">
            {{ $t('jin-ru-she-zhi') }} </a>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <div class="card mb-4 p-4 text-center bg-soft-primary"  @click="toPath('sellerUserInfo')">
          <div class="fs-16 fw-600 text-primary">
            {{ $t('fu-kuan-she-zhi') }} </div>
          <div class=" m-3">
            <img :src="payUrl" alt="">
          </div>
          <a  class="btn btn-primary">
            {{ $t('li-ji-she-zhi') }} </a>
        </div>
      </div>
    </div>

    
    <div class="card">
      <div class="card-body">
        <div class="card-title text-primary">
          <h6 class="mb-0">{{ $t('qian-12-ming-chan-pin') }}</h6>
        </div>
        <div class="aiz-carousel gutters-10 half-outside-arrow slick-initialized slick-slider" >
          <button type="button" class="slick-prev slick-arrow" style="" @click="bannerPrev">
            <i class="las la-angle-left"></i>
          </button>
          <Swipe ref="goods"  :touchable="touchable" :width="224" :show-indicators="false" :loop="false" @change="changeBanner"> 
            <SwipeItem  v-for="(item, i) in indexData.ProductRanking" :key="i">
              <goodsListBar :goodsInfo="item" :hideIcon="true" :hideIntegral="true"></goodsListBar>
            </SwipeItem>
          </Swipe>
          <button type="button" class="slick-next slick-arrow" style=""   @click="bannerNext">
            <i class="las la-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { indexInfo } from '@/api/seller'
import { Swipe, SwipeItem } from 'vant'
import goodsListBar from '@/components/goodsList'
import moment from 'moment'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    Swipe,
    SwipeItem,
    goodsListBar
  },
  data() {
    return {
      cardUrl1: require('../../../assets/imgs/card1.svg'),
      cardUrl2: require('../../../assets/imgs/card2.svg'),
      cardUrl3: require('../../../assets/imgs/card3.svg'),
      cardUrl4: require('../../../assets/imgs/card4.svg'),
      orderUrl1: require('../../../assets/imgs/order1.svg'),
      orderUrl2: require('../../../assets/imgs/order2.svg'),
      orderUrl3: require('../../../assets/imgs/order3.svg'),
      orderUrl4: require('../../../assets/imgs/order4.svg'),
      packageUrl: require('../../../assets/imgs/package.png'),
      verifyUrl: require('../../../assets/imgs/verified.png'),
      verifyUrl2: require('../../../assets/imgs/non_verified.png'),
      withdrawUrl: require('../../../assets/imgs/withdraw.svg'),
      productUrl: require('../../../assets/imgs/product.svg'),
      shopUrl: require('../../../assets/imgs/shop.svg'),
      payUrl: require('../../../assets/imgs/pay.svg'),
      isVerify: true,
      goodsList: [],
      bannerIndex: 0,
      indexData: {
        ProductRanking: []
      },
      depositMoney: '0.00',
      packageInfo: {}
    }
  },
  computed: {
    shopInfo() {
      return this.$store.state.shopInfo
    },
    userInfo() {
      return this.$store.state.userInfo
    },
     touchable() {
      return this.$store.state.touchable
    },
  },
  watch: {
    userInfo(val) {
      if (val.UserAccount) {
        this.depositMoney = parseFloat(this.userInfo.UserAccount.Deposit).toFixed(2)
      }
    },
    shopInfo(val) {
      if (val.ID) {
        this.packageInfo = this.shopInfo.ShopLevel
      }
    },
  },
  mounted() {
    this.init()
    if (this.userInfo.UserAccount) {
      this.depositMoney = parseFloat(this.userInfo.UserAccount.Deposit).toFixed(2)
    }
    if (this.shopInfo.ID) {
      this.packageInfo = this.shopInfo.ShopLevel
    }
  },
  methods: {
    resetPrice,
    init() {
      indexInfo().then(res => {
        this.indexData = res.data
      })
    },
    bannerPrev() {
      if (this.bannerIndex > 0) {
        this.$refs.goods.prev()
      }
    },
    bannerNext() {
      if (this.goodsList.length - this.bannerIndex > 7) {
        this.$refs.goods.next()
      }
    },
    changeBanner(i) {
      this.bannerIndex = i
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    getEndTime(time) {
      return moment(time).add(1, 'years').format('YYYY-MM-DD')
    }
  }
}
</script>